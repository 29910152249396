import { t } from '../../../languages';
import L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import measureJs from "leaflet-measure-js";
import measureCss from "leaflet-measure-css";
import * as turf from "@turf/turf";
import { onMounted, watch, ref, onBeforeUnmount, nextTick } from "vue";
import { ElMessage } from "element-plus";

/**
 *
 * @param {*Ref} intervalCourse 横向间距--航线间隔
 * @param {*Ref} intervalPhotos  垂直间距--照片间隔
 * @param {*Ref} routesLength  竖直航线
 * @param {*poxy} area  区域面积对象
 * @param {*poxy} mileage  飞行里程
 * @param {*poxy} coordinatesObj  经纬度坐标
 *
 */
export default function useMapOperation(
  intervalCourse,
  intervalPhotos,
  routesLength,
  area,
  mileage,
  coordinatesObj
) {
  onMounted(() => {
    initMap();
    bindingEvents();
    drawPolygon();
  });

  let map = null;
  const OSMUrl = `http://120.25.246.199:9391/map/global/{z}/{x}/{y}.png`;

  //初始化地图
  function initMap() {
    const tiandituConSess = localStorage.getItem("tiandituCon");
    map = L.map("map", {
      // zoom: 10, //设置默认缩放等级
      // minZoom: 2, //最小缩放等级
      // maxZoom: 18, //最大缩放等级
      zoomControl: false,
    });
    if (tiandituConSess === "true") {
      // 天地图
      // 天地图墨卡托
      //底图
      const image = L.tileLayer(
        "https://t{s}.tianditu.gov.cn/img_w/wmts?tk=47421dd0051ada3957f279b484932789&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}",
        {
          subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
          minZoom: 2,
          maxZoom: 22,
          maxNativeZoom: 18,
        }
      );
      //注记
      const cia = L.tileLayer(
        "https://t{s}.tianditu.gov.cn/cia_w/wmts?tk=47421dd0051ada3957f279b484932789&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}",
        {
          subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
          minZoom: 2,
          maxZoom: 22,
          maxNativeZoom: 18,
        }
      );
      //天地图图组
      const tiandiMap = L.layerGroup([image, cia]);
      tiandiMap.addTo(map);
    } else {
      let tileLayer = L.tileLayer(OSMUrl);
      tileLayer.addTo(map);
    }
    // 添加绘制工具
    // 中文
    map.pm.setLang("zh");
    // 全局设置绘制样式;
    map.pm.setPathOptions({
      color: "yellow",
      fillColor: "#00FFE8",
      fillOpacity: 0.1,
    });
    map.setView([23.1650776801146, 113.394884347512], 15);
  }

  let tifObj = {};
  // 添加tif图层
  function addTif(layerId, tifUrl, bounds) {
    const imageLayar = L.tileLayer(tifUrl, {
      maxZoom: 22,
    });
    imageLayar.addTo(map);
    tifObj[layerId] = imageLayar;
    zoomTif(bounds);
  }

  function zoomTif(arr) {
    const polygon = new L.Rectangle(L.latLngBounds(arr), {
      weight: 0,
      draggable: true,
      transform: true,
      color: "blue",
      fillColor: "transport",
      fillOpacity: 0,
    }).addTo(map);

    //polygon.getBounds(),获取经纬度边界，flyToBounds飞入到多边形
    map.flyToBounds(polygon.getBounds(), { duration: 0.25 });
  }

  function toggleTif(layerId, flag) {
    tifObj[layerId].setOpacity(flag ? 1 : 0);
  }

  let layer = ref(null); //绘制图形的图层
  let polyline = null;
  let bufferLayer = null;
  let latlngs = []; //绘制图形对应点坐标数组

  //绑定事件
  function bindingEvents() {
    map.on("pm:create", (e) => {
      // 捕捉图层
      if (e.shape === "Polygon" && e.layer._latlngs.length > 0) {
        layer.value = e.layer;
        handelLayer(e);
        editPolygon();
      }
    });
  }

  //多边形导致内部数据变化--处理函数
  function handelLayer(e) {
    latlngs = [];
    // 缓冲区图层销毁
    bufferLayer && map.removeLayer(bufferLayer);
    bufferLayer = null;
    // 遍历绘制点
    e.layer._latlngs[0].forEach((item) => {
      latlngs.push([item.lat, item.lng]);
    });

    latlngs.push(latlngs[0]); //多边形首尾相连
    publicHandleGraphics(latlngs);
  }

  //绑定多边形监听函数
  watch(layer, (newValue) => {
    if (newValue !== null) {
      newValue.on("pm:markerdragstart", () => {
        if (polyline !== null) {
          polyline.setLatLngs([]); //替换点
          startIconLayer.setOpacity(0);
          endIconLayer.setOpacity(0);
          bufferLayer && map.removeLayer(bufferLayer);
          bufferLayer = null;
        }

        deleteIconLayer.setOpacity(0);
        circleMarker && circleMarker.setOpacity(0);
      });

      // newValue.on("pm:markerdrag", throttle(handelLayer, 30)); //拖拽点事件
      newValue.on("pm:markerdragend", (e) => {
        //拖拽结束后事件
        nextTick(async () => {
          await handelLayer(e);
          if (bufferLayer !== null) {
            startIconLayer.setOpacity(1);
            endIconLayer.setOpacity(1);
          }
          deleteIconLayer.setOpacity(1);

          //同步弹窗经纬度
          if (coordinatesObj.isShowCoordinates) {
            const indexPath = e.indexPath[e.indexPath.length - 1];
            openPointDetails(e.layer._latlngs[0][indexPath], indexPath);

            craeteCircle([coordinatesObj.lat, coordinatesObj.lng]);
          }
        });
      });
      //绑定删除点事件
      newValue.on("pm:vertexremoved", (e) => {
        if (e.layer._latlngs[0].length > 0) {
          closeCircleMarker();
          handelLayer(e);
        } else {
          deletePolygon();
          noticeEmptyData();
        }
      });

      //点击航点
      newValue.on("pm:vertexclick", (e) => {
        if (e.indexPath !== undefined) {
          openPointDetails(
            e.markerEvent.latlng,
            e.indexPath[e.indexPath.length - 1]
          );
          craeteCircle([e.markerEvent.latlng.lat, e.markerEvent.latlng.lng]);
        } else {
          //点击添加航点，打开详情
          coordinatesObj.isShowCoordinates = true;
        }
      });

      //添加航点
      newValue.on("pm:vertexadded", (e) => {
        if (coordinatesObj.isShowCoordinates) {
          //防止拖拽时候打开详情面板
          openPointDetails(e.latlng, e.indexPath[e.indexPath.length - 1]);
          craeteCircle([e.latlng.lat, e.latlng.lng]);
        }
      });
    }
  });

  //清空面板对应地图数据
  function noticeEmptyData() {
    area.value = 0;
    mileage.value = 0;
    routesLength.value = 0;
  }

  /**
   * 计算点数组
   * @param {* Object} line 多边形线段GeoJSON
   * @param {* Number} setps  控制距离 -- 点之间的间隔
   */
  function computedPoint(line, setps) {
    // 多边形围起来的线段
    let pointArr = []; //点数组--真正画线

    //获取边界框
    var bboxPolygon = turf.bboxPolygon(turf.bbox(line));
    let boundaryArr = bboxPolygon.geometry.coordinates[0];
    let startLine = [boundaryArr[0], boundaryArr[1]]; //获取第一条平行线

    let isIntersect = true; //判断平行线是否相交
    let order = true; //点的取值顺序 -- 默认正
    let offsetLine = null; //偏移线
    let intersects = null; //相交点--数组
    let vsetps = 0;
    let intersectArr = []; //相交数组--未排序
    let airlineLength = 0; //竖直航线总长度

    while (isIntersect) {
      vsetps += setps;

      offsetLine = turf.lineOffset(turf.lineString(startLine), vsetps, {
        units: "meters",
      }); //获取偏移线

      intersects = turf.lineIntersect(line, offsetLine); //获取相交点

      if (intersects.features.length === 0) {
        isIntersect = false;
        routesLength.value = airlineLength.toFixed(2) * 1; //传递给面板
      } else {
        //奇数点
        // if (intersects.features.length % 2 !== 0) {
        //   intersects.features.forEach((item) => {
        //     intersectArr.push(item.geometry.coordinates);
        //   });
        // }

        //获取未排序数组
        intersectArr = [];
        intersects.features.forEach((item) => {
          intersectArr.push(item.geometry.coordinates);
        });

        if (order) {
          intersectArr.sort((pre, cur) => {
            //降序
            return cur[0] - pre[0];
          });
        } else {
          intersectArr.sort((pre, cur) => {
            //升序
            return pre[0] - cur[0];
          });
        }
        intersectArr.forEach((item) => {
          //把有顺序点存入pointArr
          pointArr.push(item);
        });

        //获取每次的竖直航线=》相加成总长度
        airlineLength += turf.rhumbDistance(
          turf.point(intersectArr[0]),
          turf.point(intersectArr[intersectArr.length - 1]),
          { units: "meters" }
        );
      }
      order = !order;
    }

    return pointArr;
  }

  /**
   * 节流函数
   * @param {* Function} fn 函数
   * @param {* Number} delay 延迟事件
   * @returns
   */
  function throttle(fn, delay) {
    let canRun = true; // 通过闭包保存一个标记
    return function () {
      if (!canRun) return;
      canRun = false;
      setTimeout(() => {
        fn.apply(this, arguments);
        canRun = true;
      }, delay);
    };
  }

  /**
   * 画线
   * @param {*Number} drawPointArr 点数组--二维
   */
  function drawLine(drawPointArr) {
    if (polyline !== null) {
      polyline.setLatLngs(drawPointArr); //替换点
    } else {
      polyline = L.polyline(drawPointArr, {
        color: "#00FFE8",
      }).addTo(map);
    }
  }
  /**
   * 绘制 辐射区
   * @param {*Number} scope 辐射范围
   */
  function drawBuffer(scope) {
    var geojson = layer.value.toGeoJSON();
    //辐射30米fu
    var buffered = turf.buffer(geojson, scope, { units: "meters" });

    if (bufferLayer !== null) {
      map.removeLayer(bufferLayer);
    }
    //辐射区添加到地图
    bufferLayer = L.geoJSON(buffered, {
      // pmIgnore: true,
      style: function () {
        return {
          // color: "transparent",
          weight: 0,
          fillOpacity: 0.2,
        };
      },
    }).addTo(map);
  }

  /**
   * 计算航线里程
   * @param {*Arrary} linePointArr 二维点数组
   */
  function computedMileage(linePointArr) {
    mileage.value =
      turf
        .length(turf.lineString(linePointArr), { units: "kilometers" })
        .toFixed(3) * 1;
  }

  //编辑
  function editPolygon() {
    layer.value.pm.enable({
      allowSelfIntersection: false,
      // removeLayerBelowMinVertexCount: false,
      // removeVertexOn: "dblclick",
      // preventMarkerRemoval: false, // 禁止右键删除点
    });
  }

  //绘制
  function drawPolygon() {
    map.pm.enableDraw("Polygon", {
      snappable: true,
      snapDistance: 20,
      allowSelfIntersection: false, //不允许交叉
      tooltips: true,
      // removeVertexOn: "click",
    });
  }

  //删除
  function deletePolygon() {
    coordinatesObj.isShowCoordinates = false; //关闭详情页

    map.removeLayer(layer.value);
    map.removeLayer(deleteIconLayer);
    circleMarker && map.removeLayer(circleMarker);

    if (polyline !== null) {
      map.removeLayer(polyline);
      map.removeLayer(bufferLayer);
      map.removeLayer(startIconLayer);
      map.removeLayer(endIconLayer);

      polyline = null;
      bufferLayer = null;
      startIconLayer = null;
      endIconLayer = null;
    }

    layer.value = null;
    deleteIconLayer = null;
    circleMarker = null;

    if (map._events.click.length < 1) {
      drawPolygon();
    }
  }

  //删除面板控制从有航线变成没航线
  function deleteBeforeHasPolyline() {
    polyline && map.removeLayer(polyline);
    startIconLayer && map.removeLayer(startIconLayer);
    endIconLayer && map.removeLayer(endIconLayer);
    polyline = null;
    startIconLayer = null;
    endIconLayer = null;
  }

  let deleteIconLayer = null; //删除图标图层
  //绘制中心删除坐标
  function drawDeletePoint() {
    const {
      geometry: { coordinates },
    } = turf.centroid(turf.polygon([latlngs]));

    if (deleteIconLayer === null) {
      const myIcon = L.icon({
        iconUrl: require("@/asset/img/polygon-mapping/deletePoint.png"),
        iconSize: [30, 30],
        className: "delete-icon",
      });
      deleteIconLayer = L.marker([coordinates[0], coordinates[1]], {
        icon: myIcon,
      }).addTo(map);
      deleteIconLayer.on("click", deletePolygon);
      deleteIconLayer
        .bindTooltip(t("149"), { direction: "top" })
        .openTooltip();
    } else {
      deleteIconLayer.closeTooltip();
      deleteIconLayer.setLatLng([coordinates[0], coordinates[1]]);
    }
  }

  let startIconLayer = null; //开始图标图层
  let endIconLayer = null; //结束图标图层
  /**
   * 绘制航线起点和终点坐标
   * @param {*Arrary} startPoint 起点坐标
   * @param {*Arrary} endPoint 终点坐标
   */
  function drawPoint(startPoint, endPoint) {
    if (startIconLayer === null) {
      const startIcon = L.icon({
        iconUrl: require("@/asset/img/polygon-mapping/startPoint.png"),
        iconSize: [22, 22],
        className: "penetrate",
      });
      startIconLayer = L.marker(startPoint, {
        icon: startIcon,
        zIndexOffset: 2000,
      }).addTo(map);

      const endIcon = L.icon({
        iconUrl: require("@/asset/img/polygon-mapping/endPoint.png"),
        iconSize: [22, 22],
        className: "penetrate",
      });
      endIconLayer = L.marker(endPoint, {
        icon: endIcon,
        zIndexOffset: 2000,
      }).addTo(map);
    } else {
      startIconLayer.setLatLng(startPoint);
      endIconLayer.setLatLng(endPoint);
    }
  }

  /**
   * 修改航线间距并绘制
   * @param {*Number} intervalCourse 间距值
   */
  async function updateRoutesInterval(intervalCourse) {
    latlngs = [];
    layer.value._latlngs[0].forEach((item) => {
      latlngs.push([item.lat, item.lng]);
    });

    latlngs.push(latlngs[0]); //多边形首尾相连

    const res = await computedLength(latlngs);
    if (!res.falge) {
      drawDeletePoint();
      return;
    } else {
      let comPointArr = computedPoint(res.line, -intervalCourse);
      if (comPointArr.length <= 0) {
        ElMessage({
          message: t("150"),
          grouping: true,
          type: "warning",
        });
        bufferLayer && map.removeLayer(bufferLayer);
        bufferLayer = null;
        return;
      }

      drawLine(comPointArr);
      drawPoint(comPointArr[0], comPointArr[comPointArr.length - 1]);
      comPointArr = null;
    }
  }

  //监听航线间隔-and-照片间隔
  watch(
    [intervalCourse, intervalPhotos],
    async (
      [newCourseValue, newPhotosValue],
      [oldCourseValue, oldPhotosValue]
    ) => {
      if (layer.value !== null) {
        if (newCourseValue !== oldCourseValue) {
          await deleteBeforeHasPolyline();
          await updateRoutesInterval(newCourseValue);
        }
        if (polyline !== null && newPhotosValue !== oldPhotosValue) {
          await drawBuffer(newPhotosValue);
        }
      }
    }
  );

  //监听
  // watch(intervalPhotos, (newValue) => {
  //   if (layer.value !== null && isDrawPolyline.value) {
  //     deleteBeforeHasPolyline()
  //     drawBuffer(newValue)
  //   }
  // })

  /**
   *处理多边形内相关数据和绘制的公共函数
   * @param {*Arrary} latlngs 多边形点坐标
   * @param {*Boolean} falge 是否需要同步多边形图形（手动输入改点需要）
   */
  async function publicHandleGraphics(latlngs, falge = false) {
    area.value = turf.area(turf.polygon([latlngs])).toFixed(2) * 1; //计算区域面积

    const res = await computedLength(latlngs);
    if (!res.falge) {
      drawDeletePoint();
      return;
    } else {
      if (falge) {
        const newLatlngs = latlngs.slice(0, -1);
        layer.value.pm.disable();
        layer.value.setLatLngs([newLatlngs]);
        editPolygon();
        bufferLayer && map.removeLayer(bufferLayer);
        circleMarker.setLatLng([
          latlngs[coordinatesObj.indexPoint][0],
          latlngs[coordinatesObj.indexPoint][1],
        ]);
      }

      let comPointArr = computedPoint(res.line, -intervalCourse.value); // 计算 正射点的列表

      if (comPointArr.length <= 0) {
        ElMessage.warning(t("150"));
        drawDeletePoint();
        return;
      }

      drawLine(comPointArr);
      drawBuffer(intervalPhotos.value);

      drawPoint(comPointArr[0], comPointArr[comPointArr.length - 1]);
      drawDeletePoint();

      computedMileage(comPointArr); //计算航线飞行长度
    }
  }

  /**
   * 打开点坐标对象弹窗
   * @param {*Object} latlngObj 点坐标对象
   * @param {*Number} indexPath 点下标
   */
  function openPointDetails(latlngObj, indexPath) {
    coordinatesObj.isShowCoordinates = true;
    coordinatesObj.lat = latlngObj.lat.toFixed(9) * 1;
    coordinatesObj.lng = latlngObj.lng.toFixed(9) * 1;
    coordinatesObj.indexPoint = indexPath;
  }

  let circleMarker = null; //圆点图层
  /**
   * 创建点击时候的圆点坐标
   * @param {*Arrary} circleArr   圆点坐标数组
   */
  function craeteCircle(circleArr) {
    if (circleMarker === null) {
      const myIcon = L.divIcon({ className: "my-div-icon" });
      circleMarker = L.marker(circleArr, { icon: myIcon }).addTo(map);
    } else {
      circleMarker.setLatLng(circleArr);
    }
    circleMarker.setOpacity(1);
  }

  /**
   * 判断规划区域是否过大
   * @param {*Arrary} latlngs 多边形数组
   */
  function computedLength(latLings) {
    let line = turf.lineString(latLings); //多边形线段
    let length = turf.length(line, { units: "kilometers" });
    if (length > 8) {
      ElMessage({
        message: t("151"),
        type: "warning",
        duration: 5000,
        grouping: true,
      });
      return { falge: false };
    } else {
      return { falge: true, line };
    }
  }

  /**
   * 测量时绘制点
   * @param {*Arrary} circleArr 点坐标
   */
  function craeteMarker(circleArr) {
    const myIcon = L.divIcon({ className: "measurement-icon" });
    L.marker(circleArr, { icon: myIcon }).addTo(map);
  }

  let drawType = null;
  let pop = null;

  let line = null; //线
  /**
   * 测距
   * @param {*Arrary} latlng 点坐标
   */
  function addOrUpdateLine(latlng) {
    craeteMarker(latlng);

    if (line) {
      // 修改
      line.addLatLng(latlng);
      line.updateMeasurements();
    } else {
      // 新增
      pop = L.popup({ closeOnClick: false })
        .setLatLng(latlng)
        .setContent(t("152"))
        .openOn(map);
      line = L.polyline([[latlng.lat, latlng.lng]], {
        showMeasurements: true,
      }).addTo(map);
    }
  }

  let drawArea = null; //面
  /**
   * 测面
   * @param {*Arrary} latlng 点坐标
   */
  function addOrUpdateArea(latlng) {
    craeteMarker(latlng);
    if (drawArea) {
      drawArea.addLatLng(latlng);
      drawArea.updateMeasurements();
    } else {
      pop = L.popup({ closeOnClick: false })
        .setLatLng(latlng)
        .setContent(t("153"))
        .openOn(map);
      drawArea = L.polygon([[latlng.lat, latlng.lng]], {
        showMeasurements: true,
      }).addTo(map);
    }
  }

  /* 需要导出的函数模块 */
  /**
   * 修改多边形图形单独点坐标
   * @param {*Number} e 修改的值
   */
  function updatePointLat(e) {
    if (e !== "") {
      latlngs[coordinatesObj.indexPoint][0] = e * 1;
      publicHandleGraphics(latlngs, true);
    } else {
      coordinatesObj.lat = latlngs[coordinatesObj.indexPoint][0];
    }
  }
  function updatePointLng(e) {
    if (e !== "") {
      latlngs[coordinatesObj.indexPoint][1] = e * 1;
      publicHandleGraphics(latlngs, true);
    } else {
      coordinatesObj.lng = latlngs[coordinatesObj.indexPoint][1];
    }
  }

  /**
   * 放大缩小层级
   * @param {*String(in、out)} type 层级变化类型
   */
  function zoom(type) {
    type === "in" && map.zoomIn();
    type === "out" && map.zoomOut();
  }

  /**
   * 获取多边形、航线、图层相关点数据，提供给保存
   */
  function getRelatedLayerData() {
    if (polyline !== null && polyline._latlngs.length > 0) {
      return {
        pointArrary: polyline._latlngs,
        polygonArrary: latlngs,
      };
    } else {
      return false;
    }
  }

  /**
   * 地图点击事件相关操作
   * @param {*Object} e 地图点击回调参数event
   */
  function clickDraw(e) {
    switch (drawType.value) {
      case "line":
        addOrUpdateLine(e.latlng);
        break;
      case "area":
        addOrUpdateArea(e.latlng);
        break;
      default:
        break;
    }
  }

  /**
   * 隐藏点击点和面板
   */
  function closeCircleMarker() {
    if (coordinatesObj.isShowCoordinates === true) {
      coordinatesObj.isShowCoordinates = false;
      circleMarker.setOpacity(0);
    }
  }

  /**
   * 绑定地图事件
   */
  function mapListening() {
    if (layer.value === null) {
      map.pm.disableDraw();
    }
    L.DomUtil.addClass(map._container, "measurement");
    map.on("click", clickDraw); //点击事件
    map.on("contextmenu", () => {
      //右击事件
      pop.close();
      pop = null;
      line = null;
      drawArea = null;
    });
  }

  /**
   * 取消地图监听事件
   */
  function closeMapListening() {
    map.off("click", clickDraw);
    map.off("contextmenu");
    if (layer.value === null) {
      drawPolygon();
    }
    L.DomUtil.removeClass(map._container, "measurement");
    if (pop !== null) {
      pop.close();
      pop = null;
    }
    line = null;
    drawArea = null;
  }

  /**
   * 测量变量同步
   * @param {*Ref} measurementType 测量变量
   */
  function getDrawType(measurementType) {
    drawType = measurementType;
  }

  /* end */

  //销毁地图
  onBeforeUnmount(() => {
    map.remove();
    tifObj = null;
  });

  return {
    updatePointLat,
    updatePointLng,
    zoom,
    getRelatedLayerData,
    closeCircleMarker,
    addTif,
    zoomTif,
    toggleTif,
    mapListening,
    closeMapListening,
    getDrawType,
  };
}
