<template>
  <div id="map" class="map"></div>
  <!-- 左边栏 -->
  <div class="leftColumn">
    <!-- 按钮 -->
    <div class="operation">
      <button class="hover_opacity" @click="goBack">
        <span class="iconfont">&#xe6ba;</span>
        <span class="title">{{ t('176') }}</span>
      </button>
      <button class="hover_opacity" @click="save">
        <span class="iconfont">&#xe626;</span>
        <span class="title">{{ t('225') }}</span>
      </button>
    </div>
    <!-- 正射图栏 -->
    <div class="orthographic">
      <div class="airName">
        <el-input
          :placeholder="t('723')"
          v-model.trim="orthographicName"
        ></el-input>
      </div>
      <div class="publicPadding">
        <img src="@/asset/img/polygon-mapping/camera.png" />
        <span>{{ t('724') }}</span>
      </div>
      <el-cascader
        v-model="cameraModel"
        :options="cameraModelList"
        :props="configuration"
        popper-class="polygon-mapping-cascader"
        @change="changeCamera"
        @expand-change="expandChangeCamera"
      />

      <div class="flex-fsb divTitle">
        <span>GSD</span>
        <span>{{ resolution }}cm/px</span>
      </div>

      <div class="divTitle">{{ t('474') }}</div>
      <el-select v-model="pictureMode" popper-class="polygon-mapping-select">
        <el-option
          v-for="item in pictureModeList"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <div class="flightRelated">
        <div v-for="item in flightRelatedData" :key="item.id">
          <span>{{ item.value }}{{ item.unit }}</span>
          <span>{{ item.lable }}</span>
        </div>
      </div>

      <div class="divTitle">{{ t('725') }}</div>
      <el-select v-model="heightMode" popper-class="polygon-mapping-select">
        <el-option
          v-for="item in heightModeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <div
        style="padding: 0 1.625rem"
        v-for="(item, index) in changeFactorsList"
        :key="item.type"
      >
        <div class="flex-sb changeModel">
          <div>
            <img :src="item.img" />
            <span>{{ item.lable }}</span>
          </div>
          <span>{{ item.value }}{{ item.unit }}</span>
        </div>
        <div class="flySpeed flex-sb">
          <button
            class="hover_opacity buttonS"
            @click="publicAddSubtract(index, -1)"
          >
            -
          </button>
          <el-slider
            :min="item.min"
            :max="item.max"
            v-model="item.value"
            :disabled="item.min >= item.max"
          ></el-slider>
          <button
            class="hover_opacity buttonS"
            @click="publicAddSubtract(index, 1)"
          >
            +
          </button>
        </div>
      </div>

      <div
        class="flex-sb changeModel"
        style="padding: 0 1.625rem; margin-bottom: 2.8125rem"
      >
        <div>
          <img src="@/asset/img/polygon-mapping/holder.png" />
          <span>{{ t('726') }}</span>
        </div>
        <span>90°</span>
      </div>

      <div class="divTitle">
        <img src="@/asset/img/polygon-mapping/complete.png" />
        <span>{{ t('727') }}</span>
      </div>

      <el-select
        class="complete-select"
        v-model="completeAction"
        popper-class="complete-action"
      >
        <el-option
          v-for="item in completeActionList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <div class="divTitle">
        <span>{{ t('728') }}</span>

        <el-switch v-model="isExit" style="margin-left: 0.75rem" />
      </div>
    </div>
  </div>
  <!-- 右边栏 -->
  <div class="iconButton">
    <ul>
      <!-- 点详情 -->
      <el-popover
        placement="left"
        popper-class="nopadding_popover"
        trigger="click"
        :visible="isShowCoordinates"
      >
        <template #reference>
          <div>
            <el-tooltip
              :content="t('729')"
              placement="top"
              effect="light"
              popper-class="tip-class"
            >
              <li @click="closeCircleMarker">
                <img src="@/asset/img/polygon-mapping/details.png" />
                <!-- <img
              v-show="isShowCoordinates"
              src="@/asset/img/polygon-mapping/details.png"
            /> -->
              </li>
            </el-tooltip>
          </div>
        </template>
        <div class="details_content">
          <div class="title">{{ t('73') }}</div>
          <el-input
            v-model="lng"
            @change="updatePointLng"
            type="number"
          ></el-input>
          <div class="title">{{ t('74') }}</div>

          <el-input
            @change="updatePointLat"
            type="number"
            v-model="lat"
          ></el-input>
        </div>
      </el-popover>

      <!-- 图层 -->
      <el-popover
        placement="left"
        popper-class="nopadding_popover"
        trigger="click"
        :visible="ischeck === 'layer'"
      >
        <template #reference>
          <div>
            <el-tooltip
              :content="t('285')"
              placement="top"
              effect="light"
              popper-class="tip-class"
            >
              <li @click="checkLayer">
                <img
                  v-show="ischeck !== 'layer'"
                  src="@/asset/img/airlinePlan/layer.png"
                />
                <img
                  v-show="ischeck === 'layer'"
                  src="@/asset/img/airlinePlan/active-layer.png"
                /></li
            ></el-tooltip>
          </div>
        </template>
        <div class="popover_content_layer">
          <div class="popover_reset">
            <el-input
              type="text"
              :placeholder="t('286')"
              clearable
              v-model="layerFilterWord"
              @change="layerChange"
            ></el-input>
            <el-icon class="iconSeach" @click="filterLayer"><Search /></el-icon>
            <div class="layerList">
              <div
                class="flex-sb"
                style="margin-top: 1.5rem"
                v-for="item in filterLayerList"
                :key="item.layerId"
                @click.self="zoomToLayer(item)"
              >
                <span @click="zoomToLayer(item)">{{ item.layerName }}</span>
                <el-checkbox
                  size="large"
                  v-model="item.display"
                  @change="changeLayarVisible(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </el-popover>

      <!-- 测绘-->
      <el-tooltip
        :content="t('288')"
        placement="top"
        effect="light"
        popper-class="tip-class"
      >
        <li @click="checkRouler">
          <img
            v-show="ischeck !== 'rouler'"
            src="@/asset/img/airlinePlan/rouler.png"
          />
          <img
            v-show="ischeck === 'rouler'"
            src="@/asset/img/airlinePlan/active-rouler.png"
          /></li
      ></el-tooltip>
      <!-- 放大缩小 -->
      <el-tooltip
        :content="t('289')"
        placement="top"
        effect="light"
        popper-class="tip-class"
      >
        <li class="hover_opacity" @click="zoom('in')">
          <img src="@/asset/img/airlinePlan/big.png" />
        </li>
      </el-tooltip>
      <el-tooltip
        :content="t('290')"
        placement="top"
        effect="light"
        popper-class="tip-class"
      >
        <li class="hover_opacity" @click="zoom('out')">
          <img src="@/asset/img/airlinePlan/small.png" />
        </li>
      </el-tooltip>
    </ul>

    <!-- 测距面板 -->
    <div class="stadio flex-a" v-if="ischeck === 'rouler'">
      <div
        class="list"
        v-for="item in rangNav"
        :class="item.value == drawType ? 'list_bg' : ''"
        :key="item.id"
        @click="selectMeasurement(item.value)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>

  <!-- 弹出框 -->
  <transition
    enter-active-class="animate__animated animate__slideInRight"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div v-if="isBack" class="goBack">
      <span
        >{{ t('318') }}。</span
      >
      <div style="text-align: right">
        <button @click="quxiao">{{ t('217') }}</button>
        <button @click="noSave">{{ t('319') }}</button>
        <button @click="backSave">{{ t('225') }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { t } from '../../languages';
import {
  defineComponent,
  toRefs,
  reactive,
  ref,
  computed,
  onBeforeMount,
  watch,
  watchEffect,
} from "vue";
import { useRouter } from "vue-router";
import { ElNotification, ElMessage } from "element-plus";
import useEarthButton from "./hook/useEarthButton";
import useMapOperation from "./hook/useMapOperation";
import "animate.css";
import polygonServer from "@/network/airPoint";
export default defineComponent({
  setup() {
    //下拉框静态数据
    const staticDataList = {
      //级联框配置
      configuration: {
        expandTrigger: "click",
      },
      //完成动作下拉框
      completeActionList: [
        { label: t("340"), value: "GO_HOME" },
        { label: t("341"), value: "AUTO_LAND" },
        { label: t("730"), value: "GO_FIRST_WAYPOINT" },
      ],
      //拍照下拉框

      pictureModeList: [
        { label: t("731"), value: "shootPhotoDistanceInterval" },
        { label: t("732"), value: "shootPhotoTimeInterval" },
      ],
      //高度下拉框
      heightModeList: [
        // { label: t("733"), value: "ABSOLUTE_HEIGHT" },
        { label: t("734"), value: "RELATIVE_HEIGHT" },
      ],
    };

    //正射对象
    const orthographicData = reactive({
      orthographicName: t("723"),
      cameraModel: "", //相机型号
      pictureMode: "shootPhotoDistanceInterval", //拍照模式
      heightMode: "RELATIVE_HEIGHT", //高度模式
      completeAction: "GO_HOME", //完成动作
      isExit: false, //是否退回
    });

    const flightRelatedData = reactive([
      { id: "area", value: 0, lable: t("735"), unit: "m²" },
      { id: "mileage", value: 0, lable: t("231"), unit: "Km" },
      { id: "flyTime", value: 0, lable: t("232"), unit: "min" },

      { id: "photo", value: 0, lable: t("736"), unit: "" },
    ]);

    //改变因素对象
    const changeFactorsList = reactive([
      {
        type: "height",
        value: 100,
        lable: t("737"),
        unit: "m",
        min: 20,
        max: 500,
        img: require("@/asset/img/polygon-mapping/height.png"),
      },
      {
        type: "mileage",
        value: 3,
        lable: t("738"),
        unit: "m/s",
        min: 1,
        max: 15,
        img: require("@/asset/img/polygon-mapping/speed.png"),
      },
      {
        type: "flySpeed",
        value: 4,
        lable: t("246"),
        unit: "m/s",
        min: 1,
        max: 15,
        img: require("@/asset/img/polygon-mapping/speed.png"),
      },
      {
        type: "course",
        value: 4,
        lable: t("739"),
        unit: "%",
        min: 10,
        max: 99,
        img: require("@/asset/img/polygon-mapping/course.png"),
      },
      {
        type: "vertical",
        value: 10,
        lable: t("740"),
        unit: "%",
        min: 10,
        max: 99,
        img: require("@/asset/img/polygon-mapping/side.png"),
      },
      // {
      //   type: "holder",
      //   value: 4,
      //   lable: t("726"),
      //   unit: "°",
      //   min: 1,
      //   max: 15,
      //   img: require("@/asset/img/polygon-mapping/holder.png"),
      // },
    ]);

    //影响间距参数--固定
    let cameraParams = ref({
      focal: 24, //焦距
      imageSize: 4 / 3000, //像元
      shutterTime: 1, //快门
      horizontalPx: 4000, //水平分辨率
      verticalPx: 3000, //竖直分辨率
    });

    // 参数数组
    const cameraParameter = {
      HA_SU_TYPE: {
        focal: 24, //焦距
        imageSize: 3.03 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 5280, //水平分辨率
        verticalPx: 3956, //竖直分辨率
      },
      CHANG_JIAO_TYPE: {
        focal: 162, //焦距
        imageSize: 1.6 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 4000, //水平分辨率
        verticalPx: 3000, //竖直分辨率
      },
      WIDE_ANGLE_3E: {
        focal: 24, //焦距
        imageSize: 3.03 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 5280, //水平分辨率
        verticalPx: 3956, //竖直分辨率
      },
      CAHNG_JIAO_3E: {
        focal: 162, //焦距
        imageSize: 1.6 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 4000, //水平分辨率
        verticalPx: 3000, //竖直分辨率
      },
      WIDE_ANGLE_3T: {
        focal: 24, //焦距
        imageSize: 0.8 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 8000, //水平分辨率
        verticalPx: 6000, //竖直分辨率
      },
      CAHNG_JIAO_3T: {
        focal: 162, //焦距
        imageSize: 1.6 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 4000, //水平分辨率
        verticalPx: 3000, //竖直分辨率
      },
      WIDE_ANGLE_M30: {
        focal: 24, //焦距
        imageSize: 1.6 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 4000, //水平分辨率
        verticalPx: 3000, //竖直分辨率
      },
      // 'ZOOM_M30':{
      // focal: 3.5, //焦距
      // imageSize: 0.8 / 10000, //像元
      // shutterTime: 1, //快门
      // horizontalPx: 8000, //水平分辨率
      // verticalPx: 6000, //竖直分辨率
      // },
      INFRA_RED_M30: {
        focal: 40, //焦距
        imageSize: 12 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 640, //水平分辨率
        verticalPx: 512, //竖直分辨率
      },
      VISIBLE_LIGHT_YU2: {
        focal: 24, //焦距
        imageSize: 1.6 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 8000, //水平分辨率
        verticalPx: 6000, //竖直分辨率
      },
      INFRA_RED_YU2: {
        focal: 38, //焦距
        imageSize: 12 / 1000, //像元
        shutterTime: 1, //快门
        horizontalPx: 640, //水平分辨率
        verticalPx: 512, //竖直分辨率
      },
    };

    // 相机数组
    const camerArr = ref([
      {
        label: "DJI Mavic 3",
        value: "DJI_MAVIC_3",
        children: [
          {
            label: t("741"),
            value: "HA_SU_TYPE",
          },
          {
            label: t("742"),
            value: "CHANG_JIAO_TYPE",
          },
        ],
      },
      {
        label: "DJI Mavic 3E",
        value: "DJI_MAVIC_3E",
        children: [
          {
            label: t("743"),
            value: "WIDE_ANGLE_3E",
          },
          {
            label: t("744"),
            value: "CAHNG_JIAO_3E",
          },
        ],
      },
      {
        label: "DJI Mavic 3T",
        value: "DJI_MAVIC_3T",
        children: [
          {
            label: t("743"),
            value: "WIDE_ANGLE_3T",
          },
          {
            label: t("744"),
            value: "CAHNG_JIAO_3T",
          },
        ],
      },
      {
        label: t("745"),
        value: "JING_WEI_M30",
        children: [
          {
            label: t("743"),
            value: "WIDE_ANGLE_M30",
          },
          // {
          //   label:t('746'),
          //   value:'ZOOM_M30'
          // },
          {
            label: t("747"),
            value: "INFRA_RED_M30",
          },
        ],
      },
      {
        label: t("748"),
        value: "YU_2",
        children: [
          {
            label: t("749"),
            value: "VISIBLE_LIGHT_YU2",
          },
          {
            label: t("747"),
            value: "INFRA_RED_YU2",
          },
        ],
      },
    ]);

    let cameraModelList = ref([]);
    onBeforeMount(async () => {
      // const res = await polygonServer.getCameraModel();
      // cameraModelList.value = res.resultData;
      cameraModelList.value = camerArr.value;
      orthographicData.cameraModel = [
        cameraModelList.value[0].value,
        cameraModelList.value[0].children[0].value,
      ]; //默认值
      // await changeCamera(orthographicData.cameraModel);
      changeCamera(orthographicData.cameraModel);
    });

    //改变相机型号，请求相机参数
    async function changeCamera(changeValue) {
      // const params = {
      //   cameraModelEnum: changeValue[0],
      //   lenModelEnum: changeValue[1],
      // };
      // const res = await polygonServer.getCameraParam(params);
      // if (res.resultData) {
      //   cameraParams.value = res.resultData;
      // } else {
      //   ElMessage.warning(t("750"));
      // }
      cameraParams.value = cameraParameter[changeValue[1]];
    }
    function expandChangeCamera(params) {
      cameraModelList.value.map((item) => {
        if (item.value === params[0]) {
          orthographicData.cameraModel = [item.value, item.children[0].value];
          cameraParams.value = cameraParameter[item.children[0].value];
        }
      });
    }

    //计算地面采样距离
    let resolution = computed(() =>
      (
        (changeFactorsList[0].value *
          100 *
          (cameraParams.value.imageSize / 10)) /
        (cameraParams.value.focal / 10)
      ).toFixed(2)
    );
    //垂直成像范围
    let vertical_range = computed(
      () => (resolution.value * cameraParams.value.verticalPx) / 100
    );
    //水平成像范围
    let horizontal_range = computed(
      () => (resolution.value * cameraParams.value.horizontalPx) / 100
    );

    //计算航线间隔
    let intervalCourse = computed(
      () =>
        (
          horizontal_range.value *
          (1 - changeFactorsList[4].value / 100)
        ).toFixed(2) * 1
    );

    //计算照片范围--垂直间距
    let intervalPhotos = computed(() => {
      return (
        (Math.max(vertical_range.value, horizontal_range.value).toFixed(2) *
          1) /
        2
      );
    });

    let routesLength = ref(0); //竖直航线长度
    //照片数
    flightRelatedData[3].value = computed(() => {
      return Math.ceil(
        routesLength.value /
          (vertical_range.value * (1 - changeFactorsList[3].value / 100))
      );
    });

    //飞行速度最大值
    changeFactorsList[2].max = computed(() => {
      return Math.ceil(
        (vertical_range.value * (1 - changeFactorsList[3].value / 100)) /
          cameraParams.value.shutterTime
      );
    });

    //飞行时间
    flightRelatedData[2].value = computed(() =>
      Math.ceil(
        (flightRelatedData[1].value * 1000) / changeFactorsList[2].value / 60
      )
    );

    function publicAddSubtract(index, value) {
      if (
        changeFactorsList[index].value + value > changeFactorsList[index].max ||
        changeFactorsList[index].value + value < changeFactorsList[index].min
      ) {
        return;
      }

      changeFactorsList[index].value += value;
    }

    //经纬度坐标
    const coordinatesObj = reactive({
      indexPoint: -1,
      lat: 0, //纬度
      lng: 0, //经度
      isShowCoordinates: false,
    });

    //调用地图hook
    const {
      updatePointLng,
      updatePointLat,
      zoom,
      getRelatedLayerData,
      closeCircleMarker,
      addTif,
      zoomTif,
      toggleTif,
      mapListening,
      closeMapListening,
      getDrawType,
    } = useMapOperation(
      intervalCourse,
      intervalPhotos,
      routesLength,
      flightRelatedData[0],
      flightRelatedData[1],
      coordinatesObj
    );
    /* 保存退出模块 */
    let isBack = ref(false);
    let router = useRouter();
    //返回按钮函数对象
    const revertFun = {
      goBack: () => {
        saveElnotfy && saveElnotfy.close();
        const layers = getRelatedLayerData(); //获取图层的数据
        if (layers) {
          isBack.value = true;
        } else {
          router.go(-1);
        }
      },
      //取消
      quxiao: () => {
        isBack.value = false;
      },

      //不保存
      noSave: () => {
        isBack.value = false;
        router.go(-1);
      },

      //退出界面-保存接口
      backSave: function () {
        isBack.value = false;
        save();
        router.go(-1);
      },

      saveEffect: function () {
        return ElNotification({
          title: t("130"),
          dangerouslyUseHTMLString: true,
          message: `<div class='saveContent'>${ t('751') }</div>`,
          duration: 2000,
          customClass: "notifyStyle",
          type: "success",
        });
      },
    };

    let saveElnotfy = null; //ElNotification对象--用于关闭
    let routeId = null; //存放保存后，接口放回的id
    // let isAnewSave = false; //是否重新保存
    // let anewWatch = null; //保存的监听对象

    // onMounted(() => {
    //   anewWatch = saveWatch();
    // });

    async function save() {
      if (orthographicData.orthographicName === "") {
        ElMessage.warning(t("325"));
        return;
      }

      const res = await polygonServer.validationName({
        routeName: orthographicData.orthographicName,
        routeId,
      });

      if (res.resultStatus) {
        const layers = getRelatedLayerData(); //获取地图的数据
        if (layers) {
          //传递给后端数据对象
          const airlineData = {
            routePlanName: orthographicData.orthographicName,
            statistic: {
              airWay: flightRelatedData[1].value * 1000,
              amount: layers.pointArrary.length,
              expectedTime: flightRelatedData[2].value * 60,
            },
            airspeed: changeFactorsList[2].value,
            routePoint: [],
          };
          let shootPhoto = null;
          if (orthographicData.pictureMode === "shootPhotoDistanceInterval") {
            shootPhoto = Math.round(
              vertical_range.value * (1 - changeFactorsList[3].value / 100)
            );
          } else {
            shootPhoto = Math.round(
              (flightRelatedData[1].value * 1000) / changeFactorsList[2].value
            );
          }
          //点数据对象
          const routePointParams = {
            pointLat: 0,
            pointLog: 0,
            pointAltitude: changeFactorsList[0].value,
            [orthographicData.pictureMode]: shootPhoto,
            pointYaw: 180,
            flySpeed: changeFactorsList[2].value,
            angleOfPitch: 90,
            addAction: [],
          };

          //点坐标数组
          const pointArrary = [];
          layers.pointArrary.forEach((item) => {
            routePointParams.pointLat = item.lat;
            routePointParams.pointLog = item.lng;
            pointArrary.push([item.lat, item.lng]);
            airlineData.routePoint.push({ ...routePointParams });
          });

          const airlinePanelData = {
            pointArrary, //航线点数组
            polygonArrary: layers.polygonArrary, //多边形数组
            orthographicData,
            changeFactorsValue: {
              height: changeFactorsList[0].value,
              mileage: changeFactorsList[1].value,
              flySpeed: changeFactorsList[2].value,
              course: changeFactorsList[3].value,
              vertical: changeFactorsList[4].value,
            },
            flightRelatedValue: {
              area: flightRelatedData[0].value,
              mileage: flightRelatedData[1].value,
              flyTime: flightRelatedData[2].value,
              photo: flightRelatedData[3].value,
            },
          };
          airlineData.airlinePanelData = airlinePanelData;
          let routeJson = JSON.stringify(airlineData);

          let data = { routeId, routeJson };

          polygonServer.savePolygonMapping(data).then((res) => {
            if (res.resultStatus) {
              routeId = res.resultData;
              isBack.value = false;
              revertFun.saveEffect();
              // anewWatch && anewWatch();
              // anewWatch = saveWatch();
            }
          });
        } else {
          ElMessage.warning(t("752"));
          return;
        }

        // isAnewSave = false; //是否需要重新保存
        let routeJson = JSON.stringify(airPlaneData);
        let data = { routeId, routeJson };
        airPointServer.saveAirPoint(data).then((res) => {
          if (res.resultStatus) {
            routeId = res.resultData;
            revertFun.saveEffect();
            // anewWatch && anewWatch();
            // anewWatch = saveWatch();
          }
        });
      }
    }

    //监听整个航线是否变化
    function saveWatch() {
      // return watch(airPlaneData, () => {
      //   isAnewSave = true;
      //   anewWatch && anewWatch();
      //   anewWatch = null;
      // });
    }

    /* 右边栏地图按钮--第一个除外 */
    const {
      buttonData,
      rangNav,
      drawType,
      mapButtonFun,
      filterLayerList,
      layerFilterWord,
      getAllLayers,
      layerChange,
      filterLayer,
      zoomToLayer,
      changeLayarVisible,
    } = useEarthButton(
      addTif,
      zoomTif,
      toggleTif,
      closeCircleMarker,
      mapListening,
      closeMapListening
    );

    getAllLayers();
    getDrawType(drawType);

    watch(
      //避免第一个按钮和下边按钮冲突打开
      () => coordinatesObj.isShowCoordinates,
      (newValue) => {
        if (newValue) {
          buttonData.ischeck = "";
        }
      }
    );

    return {
      ...toRefs(orthographicData),
      ...toRefs(buttonData),
      ...staticDataList, //下拉框静态数据
      ...toRefs(coordinatesObj),
      drawType,
      cameraModelList,
      resolution,
      isBack,
      flightRelatedData,
      changeFactorsList,
      rangNav,

      //函数对象
      ...mapButtonFun,
      ...revertFun,

      //函数
      save,
      publicAddSubtract,
      changeCamera,
      expandChangeCamera,
      //地图
      updatePointLng,
      updatePointLat,
      zoom,
      closeCircleMarker,
      filterLayerList,
      layerFilterWord,
      getAllLayers,
      layerChange,
      filterLayer,
      zoomToLayer,
      changeLayarVisible,
    };
  },
});
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
<style lang="less">
.nopadding_popover.el-popper.is-light .el-popper__arrow::before {
  background: rgba(11, 79, 122, 0.86) !important;
  border-color: rgba(11, 79, 122, 0.86) !important;
}
/* 地图样式 */
.map {
  .my-div-icon {
    width: 16px !important;
    height: 16px !important;
    background-color: #00ff00;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: -9px 0 0 -8px !important;
    z-index: 1000 !important;
    pointer-events: none !important;
  }

  // .marker-icon:focus {
  //   background-color: #00ff00;
  // }
  .leaflet-interactive {
    cursor: auto;
  }

  .marker-icon,
  .delete-icon {
    cursor: pointer;
  }

  .marker-icon-middle {
    background-color: #389f1e !important;
    opacity: 1 !important;
    // border: 4px solid #389f1e !important;
    border: 0 !important;
    width: 11px !important;
    height: 11px !important;
  }

  .marker-icon-middle::before {
    content: "";
    position: absolute;
    width: 1px;
    border-top: 7px solid;
    margin-top: 2px;
    margin-left: 5px;
    color: #fff;
  }

  .marker-icon-middle::after {
    content: "";
    position: absolute;
    height: 1px;
    margin-left: 2px;
    margin-top: 5px;
    border-left: 7px solid;
    color: #fff;
  }

  .measurement-icon {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    pointer-events: none !important;
    z-index: -1 !important;
  }

  .penetrate {
    pointer-events: none !important;
  }
}
//测距侧面用的鼠标样式
.measurement {
  cursor: crosshair;
}
.tip-class {
  color: #000;
  z-index: 2500 !important;
}
</style>
