import { t } from '../../../languages';
import {
  reactive,
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import polygonServer from "@/network/airPoint";
import {
  ElMessage
} from 'element-plus';

export default function useEarthButton(addTif, zoomTif, toggleTif, closeCircleMarker, mapListening,
  closeMapListening) {
  const buttonData = reactive({
    ischeck: "", //点击各个按钮操作判断

    layerFilterWord: "", //图层关键字
    layarList: [], //图层列表
    filterLayerList: [], //图层筛选列表
    visibleLayar: [], //已经实例化过的图层列表
    cesiumTimer: null,
  });
  /* 测距按钮不变数据 */
  const rangNav = [{
      id: 1,
      name: t("146"),
      value: 'line'
    },
    {
      id: 2,
      name: t("147"),
      value: 'area'
    },
  ];


  let drawType = ref('line') //测量类型

  /* 地图按钮函数对象 */
  const mapButtonFun = {
    //选中图层按钮
    checkLayer: () => {
      if (buttonData.ischeck === 'rouler') {
        closeMapListening()
      }

      if (buttonData.ischeck !== "layer") {
        closeCircleMarker()
        buttonData.ischeck = "layer";
      } else {
        buttonData.ischeck = "";
      }
    },


    //选中尺
    checkRouler: () => {
      if (buttonData.ischeck !== "rouler") {
        closeCircleMarker()
        mapListening()
        buttonData.ischeck = "rouler";
      } else {
        buttonData.ischeck = "";
        closeMapListening()
      }
    },

    /**
     * 选择测量类型
     * @param {*String} measurementType 测量类型
     */
    selectMeasurement: (measurementType) => {
      drawType.value = measurementType
    }

  };


  const layerList = ref([]); //原始图层列表
  const filterLayerList = ref([]); //过滤图层列表
  const layerFilterWord = ref(''); //过滤搜索框

  // 获取所有图层
  async function getAllLayers() {
    const planRemembLayersJson = localStorage.getItem('tifRemembLayers');
    const planRemembLayers = planRemembLayersJson !== null ? JSON.parse(planRemembLayersJson) : [];
    const res = await polygonServer.getAllLayers();
    if (res.resultStatus) {
      const data = res.resultData;
      layerList.value = data.filter((item) => {
        if (item.layerType === "TIF") {
          if (planRemembLayers.includes(item.layerId)) {
            // 显示图层
            item.display = true;
            nextTick(() => {
              changeLayarVisible(item, false);
            })
          } else {
            item.display = false;
          }
          return item;
        }
      });
      filterLayerList.value = layerList.value;
    }
  }

  /* 筛选图层输入框改变 */
  function layerChange() {
    if (layerFilterWord.value === "") {
      filterLayerList.value = layerList.value;
    }
  }

  /* 筛选图层列表 */
  function filterLayer() {
    filterLayerList.value = layerList.value.filter((item) => {
      if (item.layerName.indexOf(layerFilterWord.value) !== -1) {
        return item;
      }
    });
  }

  const visibleLayar = []; //可见列表
  /* 定位图层 */
  function zoomToLayer(item) {
    const layerId = item.layerId;
    if (visibleLayar.includes(layerId)) {
      const layerItem = layerList.value.find((i) => {
        return i.layerId === layerId;
      })
      if (!layerItem) return;
      const tifInfo = JSON.parse(layerItem.tifMetaInfo);
      const bounds = [
        [tifInfo.bounds.south, tifInfo.bounds.west],
        [tifInfo.bounds.north, tifInfo.bounds.east]
      ]
      zoomTif(bounds);
    } else {
      ElMessage.warning(t("148"));
    }
  }


  /* 切换图层显示 */
  function changeLayarVisible(item, isZoomTo = true) {
    const layerId = item.layerId;
    const isCheck = item.display;
    const layerUrl = item.layerUrl;
    if (visibleLayar.includes(layerId)) {
      // 选中过
      // toggleImagery(layerId, isCheck);
      toggleTif(layerId, isCheck);
    } else {
      // 没有选中过
      visibleLayar.push(layerId);
      const tifInfo = JSON.parse(item.tifMetaInfo);
      const bounds = [
        [tifInfo.bounds.south, tifInfo.bounds.west],
        [tifInfo.bounds.north, tifInfo.bounds.east]
      ]
      addTif(layerId, layerUrl, bounds);
    }
  }

  /* 记住已选择的图层id */
  function rememberLayers() {
    const layers = [];
    for (let i = 0; i < filterLayerList.value.length; i++) {
      if (filterLayerList.value[i].display === true) {
        layers.push(filterLayerList.value[i].layerId);
      }
    }
    localStorage.setItem('tifRemembLayers', JSON.stringify(layers));
  }

  onMounted(() => {
    window.addEventListener("unload", rememberLayers);
  })

  onBeforeUnmount(()=>{
    window.removeEventListener("unload", rememberLayers);
  })



  return {
    buttonData,
    rangNav,
    drawType,
    mapButtonFun,
    filterLayerList,
    layerFilterWord,
    getAllLayers,
    layerChange,
    filterLayer,
    zoomToLayer,
    changeLayarVisible,
  }
}